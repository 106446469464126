import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"

const error = () => {
  return (
    <>
      <Seo title="Error" />
      <Layout>
        <div className="section">
          <div className="container container__tight">
            <h1>Oops...</h1>
            <h3>...looks like this page has moved or no longer exists.</h3>
            <p>
              Please navigate to a different page from the menu above, or return home
            </p>
            <Button text="Return Home" to="/" as={Link} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default error
